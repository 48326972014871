import React from "react";
import s from "pages/registerCard/styles.module.scss";

const PromoDescription: React.FC = () => (
  <div>
    <h3 className={s.title}>Глобальное потепление?!</h3>
    <div className={s.description}>
      Глобальное потепление - это хорошо! Если речь идёт об отношениях между
      людьми, о дружбе, о желании чаще встречаться и топить лёд! Иван Раковар
      объявляет, что несет ответсвенность за глобальное потепление... между
      людьми!
    </div>

    <h3 className={s.title}>Можно получать CashBack!?</h3>
    <div className={s.description}>
      С каждой покупки в Раковарне Ивана Раковара на карту начисляются 5%
      бонусных баллов от суммы заказа. Они сохраняются в течении 180 дней.
    </div>

    <h3 className={s.title}>Оплата покупок бонусными баллами!?</h3>
    <div className={s.description}>
      Оплачивайте до 50% суммы заказа бонусными баллами.
    </div>

    <h3 className={s.title}>Когда можно пользоваться!?</h3>
    <div className={s.description}>
      Бонусные баллы начисляются на счёт в течении 24 часов с момента
      регистрации.
    </div>
  </div>
);

export default PromoDescription;
