import {
  atom,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { RegisterInBonusProgramStepVariants } from "./registerInBonusProgramStepVariants";

interface RegisterInBonusProgramState {
  name?: string;
  phone?: string;
  lastName?: string;
  email?: string;
  birthday?: string;
  sex?: 0 | 1 | 2;
  city?: string;
  favourite?: string;
  howDidFindOut?: string;
}

const $registerInBonusProgramState = atom<RegisterInBonusProgramState>({
  key: "registerInBonusProgram",
  default: {},
});

const $registerInBonusProgramStep = atom<RegisterInBonusProgramStepVariants>({
  key: "registerInBonusProgramStep",
  default: RegisterInBonusProgramStepVariants.enterPhone,
});

interface Restaurant {
  name: string;
  code: string;
  id: string;
  address: string;
  iIkoCategoryId: string;
}

interface RegisterInBonusProgramCity {
  name: string;
  restaurants?: Restaurant[];
  id?: string;
}

export interface RegisterInBonusProgramAsyncParams {
  cities: {
    [key: string]: RegisterInBonusProgramCity;
  };
  loading: boolean;
}

const $registerInBonusProgramAsyncParams =
  atom<RegisterInBonusProgramAsyncParams>({
    key: "registerInBonusProgramAsyncParams",
    default: {
      cities: {},
      loading: true,
    },
  });

export function useUpdateRegisterInBonusProgramAsyncData() {
  return useRecoilTransaction_UNSTABLE<
    [{ asyncData: RegisterInBonusProgramAsyncParams }]
  >(
    ({ set }) =>
      ({ asyncData }) => {
        set($registerInBonusProgramAsyncParams, asyncData);
      },
    []
  );
}

export function useRegisterInBonusProgramAsyncParamsData() {
  return useRecoilValue($registerInBonusProgramAsyncParams);
}

export function useRegisterInBonusProgramData() {
  return useRecoilValue($registerInBonusProgramState);
}

export function useRegisterInBonusProgramStep() {
  return useRecoilValue($registerInBonusProgramStep);
}

export function useUpdateRegisterInBonusProgramStep() {
  return useSetRecoilState($registerInBonusProgramStep);
}

export function useUpdateRegisterInBonusProgramState() {
  return useRecoilTransaction_UNSTABLE<[RegisterInBonusProgramState]>(
    ({ set }) =>
      (updatingData) => {
        set($registerInBonusProgramState, (oldState) => ({
          ...oldState,
          ...updatingData,
        }));
      }
  );
}
