import { useEffect } from "react";
import { app } from "app/api/firebase";
import { useUpdateUserInfoLocale } from "app/state/auth/useUserInfo";
import { getUserInfo, prepareUserInfo } from "app/api/getData/getUserInfo";
import { useUpdateRegisterInBonusProgramStep } from "app/state/registerInBonusProgram";
import { RegisterInBonusProgramStepVariants } from "app/state/registerInBonusProgram/registerInBonusProgramStepVariants";
import { LOADING } from "app/state/auth/userInfo";

export function useSyncUserInfo() {
  const updateUserInfoLocale = useUpdateUserInfoLocale();
  const updateRegisterInBonusProgramStep =
    useUpdateRegisterInBonusProgramStep();
  useEffect(() => {
    let returnFn: any;
    app.auth().onAuthStateChanged(async (user) => {
      if (typeof returnFn === "function") {
        returnFn();
        returnFn = null;
      }

      try {
        updateUserInfoLocale(LOADING);
        if (!user?.uid || !user.phoneNumber) {
          updateUserInfoLocale(null);
          updateRegisterInBonusProgramStep(
            RegisterInBonusProgramStepVariants.enterPhone
          );
          return;
        }
        const userInfo = await getUserInfo();
        if (!userInfo?.iIkoCardsId) {
          updateRegisterInBonusProgramStep(
            RegisterInBonusProgramStepVariants.enterUserInformation
          );
        }

        const preparedUserInfo = userInfo && prepareUserInfo(userInfo);

        updateUserInfoLocale(preparedUserInfo);

        returnFn = app
          .firestore()
          .collection("customers")
          .doc(user.uid)
          .onSnapshot(
            {
              includeMetadataChanges: true,
            },
            async (doc) => {
              const newUserInfo = await doc.data();
              if (!newUserInfo) {
                return;
              }
              const preparedNewUserInfo =
                newUserInfo && prepareUserInfo(newUserInfo);

              updateUserInfoLocale(preparedNewUserInfo);
            }
          );
      } catch (e) {
        console.warn("Sync ser info error. ", e);
      }
    });
  }, []);
}
