import { app } from "app/api/firebase";

export async function verifySms(params: {
  phone: string | number;
  code: string | number;
}): Promise<string> {
  const { data } = await app.functions().httpsCallable("verifySms")(params);

  if (data.isOk || !data.token) {
    return data.token;
  }
  throw "Неверный код";
}
