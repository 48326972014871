import { app } from "app/api/firebase";

export function sendSmsFromServer(phone: string) {
  return app
    .functions()
    .httpsCallable("sendSmsVerify")({
      phone,
    })
    .then(
      (d) =>
        d.data as {
          isOk: boolean;
          message?: "Invalid code";
        }
    );
}
