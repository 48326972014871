import React from "react";
import { useUserInfo } from "app/state/auth/useUserInfo";
import { Row, Typography } from "antd";
import logo from "assets/logo.svg";
import { numWords } from "app/words";
import PromoDescription from "components/promoDescription";
import { logout } from "app/api/firebase/helpers";
import s from "./styles.module.scss";

const ShowBalancePage: React.FC = () => {
  const { balance, firstName } = useUserInfo();
  return (
    <div className={s.container}>
      <Row justify={"center"}>
        <img src={logo} alt={""} />
      </Row>
      <Typography.Title
        level={3}
        className={`${s.boldItalicFont} ${s.helloText}`}
      >
        Привет, {firstName}
      </Typography.Title>

      <Typography.Text className={s.inYouBalanceText}>
        На твоем счету:
      </Typography.Text>

      <Typography.Title
        level={2}
        className={`${s.boldItalicFont} ${s.balanceText}`}
      >
        {balance} {numWords.bonus(balance)}
      </Typography.Title>

      <PromoDescription />

      <Row justify={"center"}>
        <Typography.Text type={"danger"} onClick={logout}>
          Выход
        </Typography.Text>
      </Row>
    </div>
  );
};

export default ShowBalancePage;
