import { RegisterInBonusProgramAsyncParams } from "app/state/registerInBonusProgram";
import { app } from "app/api/firebase";

export function getRegisterInBonusProgramAsyncParams(): Promise<
  RegisterInBonusProgramAsyncParams["cities"]
> {
  return app
    .functions()
    .httpsCallable("getRegisterInBonusProgramAsyncParams")()
    .then((d) => d.data?.cities);
}
