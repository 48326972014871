import React from "react";
import { Row, Typography } from "antd";
import logo from "assets/logo.svg";
import PromoDescription from "components/promoDescription";
import RegisterInBonusProgramVerifyPhoneNumber from "pages/registerCard/RegisterInBonusProgramVerifyPhoneNumber";
import { RegisterInBonusProgramStepVariants } from "app/state/registerInBonusProgram/registerInBonusProgramStepVariants";
import {
  useRegisterInBonusProgramAsyncParamsData,
  useRegisterInBonusProgramStep,
} from "app/state/registerInBonusProgram";
import RegisterInBonusProgramEnterUserInformationUserInfo from "pages/registerCard/fillUserInfo";
import { logout } from "app/api/firebase/helpers";
import { useIsAuth } from "app/state/auth/useUserInfo";
import Loader from "components/loader";
import s from "./styles.module.scss";
import EnterPhoneNumber from "./enterPhoneNumber";

const fillFormSteps: {
  [key in RegisterInBonusProgramStepVariants]: React.ReactNode;
} = {
  [RegisterInBonusProgramStepVariants.enterPhone]: <EnterPhoneNumber />,
  [RegisterInBonusProgramStepVariants.verifyPhone]: (
    <RegisterInBonusProgramVerifyPhoneNumber />
  ),
  [RegisterInBonusProgramStepVariants.enterUserInformation]: (
    <RegisterInBonusProgramEnterUserInformationUserInfo />
  ),
};

const fillFormStepsHeaderContent: {
  [key in RegisterInBonusProgramStepVariants]: React.ReactNode;
} = {
  [RegisterInBonusProgramStepVariants.enterPhone]: (
    <>
      <h4 className={s.lightTitle}>
        Программа лояльности Ивана Раковара ® - Глобальное потепление!
      </h4>
      <div
        style={{
          fontSize: 14,
          color: "rgba(0, 0, 0, 0.49)",
        }}
      >
        <p
          style={{
            fontSize: 14,
            color: "rgb(255,0,0)",
            fontWeight: 600,
          }}
        >
          [!] Внимание. Из-за санкций некоторые операторы связи не могут пройти
          авторизацию в программе лояльности. Для успешного прохождения
          регистрации вы можете воспользоваться любым из доступных VPN сервисов.
          Мы уже работаем над решением возникшей проблемы. Приносим свои
          извинения за доставленные неудобства.
        </p>
        Всего два шага и Вы с нами! Первый шаг - введите действующий номер
        телефона!
        <br />
        <strong>
          Введите последние 4 цифры номера телефона с которого сейчас поступит
          звонок
        </strong>
        <br />
      </div>
    </>
  ),
  [RegisterInBonusProgramStepVariants.verifyPhone]: (
    <>
      <h4 className={s.lightTitle}>
        Программа лояльности Ивана Раковара ® - Глобальное потепление!
      </h4>
      <div
        style={{
          fontSize: 14,
          color: "rgba(0, 0, 0, 0.49)",
        }}
      >
        <p
          style={{
            fontSize: 14,
            color: "rgb(255,0,0)",
            fontWeight: 600,
          }}
        >
          [!] Внимание. Из-за санкций некоторые операторы связи не могут пройти
          авторизацию в программе лояльности. Для успешного прохождения
          регистрации вы можете воспользоваться любым из доступных VPN сервисов.
          Мы уже работаем над решением возникшей проблемы. Приносим свои
          извинения за доставленные неудобства.
        </p>
        Всего два шага и Вы с нами! Первый шаг - введите действующий номер
        телефона!
        <br />
        <strong>
          Введите последние 4 цифры номера телефона с которого сейчас поступит
          звонок
        </strong>
        <br />
      </div>
    </>
  ),
  [RegisterInBonusProgramStepVariants.enterUserInformation]: (
    <>
      <h4 className={s.lightTitle}>Спасибо! Осталось совсем чуть-чуть!</h4>
      <div
        style={{
          fontSize: 14,
          color: "rgba(0, 0, 0, 0.49)",
        }}
      >
        Шаг второй! Заполните несколько полей формы, нажмите кнопку “Отправить”
        и загрузите карту в свой смартфон! Приветсвенные бонусы будут начислены
        автоматически!
      </div>
    </>
  ),
};

const RegisterInBonusProgramPage: React.FC = () => {
  const currentStep = useRegisterInBonusProgramStep();
  const asyncData = useRegisterInBonusProgramAsyncParamsData();
  const isAuth = useIsAuth();
  return (
    <div
      style={{
        padding: "40px 16px",
      }}
    >
      <Row justify="center">
        <img src={logo} alt="" />
      </Row>
      {fillFormStepsHeaderContent[currentStep]}
      {asyncData.loading ? <Loader size={"s"} /> : fillFormSteps[currentStep]}
      <PromoDescription />
      {isAuth && (
        <Row
          justify={"center"}
          style={{
            marginTop: 20,
          }}
        >
          <Typography.Text type={"danger"} onClick={logout}>
            Выход
          </Typography.Text>
        </Row>
      )}
    </div>
  );
};

export default RegisterInBonusProgramPage;
