import { app } from "app/api/firebase/index";

export function authFromCustomToken(token: string) {
  return app.auth().signInWithCustomToken(token);
}

export function logout() {
  return app.auth().signOut();
}

export function loginWithCustomToken(token: string) {
  return app.auth().signInWithCustomToken(token);
}
