import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import "app/api/firebase";
import { RoutesList } from "app/routes/routesList";
import { useSyncUserInfo } from "app/state/auth/useSyncUserInfo";
import { useUserInfoIsLoading } from "app/state/auth/useUserInfo";
import { toast, Toaster } from "react-hot-toast";
import Loader from "components/loader";
import "moment/locale/ru";
import ruLocale from "antd/lib/locale/ru_RU";
import { getRegisterInBonusProgramAsyncParams } from "app/api/getData/getRegisterInBonusProgramAsyncParams";
import { useUpdateRegisterInBonusProgramAsyncData } from "app/state/registerInBonusProgram";

export const App: React.FC = () => {
  useSyncUserInfo();
  const userInfoLoading = useUserInfoIsLoading();
  const updateRegisterInBonusProgramAsyncParams =
    useUpdateRegisterInBonusProgramAsyncData();
  useEffect(() => {
    (async () => {
      try {
        await getRegisterInBonusProgramAsyncParams().then((newCities) =>
          updateRegisterInBonusProgramAsyncParams({
            asyncData: {
              cities: newCities,
              loading: false,
            },
          })
        );
      } catch (e) {
        toast.error("Не удалось загрузить список городов");
      }
    })();
  }, []);

  if (userInfoLoading) {
    return <Loader size={"l"} />;
  }

  return (
    <ConfigProvider locale={ruLocale}>
      <div className="App">
        <BrowserRouter>
          <RoutesList />
          <Toaster />
        </BrowserRouter>
      </div>
    </ConfigProvider>
  );
};
