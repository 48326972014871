import {
  useRecoilCallback,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
} from "recoil";
import {
  $isAuth,
  $userInfo,
  $userInfoUpdating,
  $userProfileField,
  Customer,
  LOADING,
} from "app/state/auth/userInfo";
import { updateUserInfoInServer } from "app/api/sendData/updateUserInfo";
import { registerCustomerInServer } from "app/api/sendData/registerCustomer";
import { toast } from "react-hot-toast";
import { numWords } from "app/words";

export function useUserInfo(): Customer {
  const userInfo = useRecoilValue($userInfo);

  if (!userInfo) {
    throw "User info not found";
  }

  if (userInfo === LOADING) {
    throw "User info is Loading";
  }

  return userInfo;
}

export function useIsAuth() {
  return useRecoilValue($isAuth);
}

export function useUpdateUserInfoLocale() {
  return useRecoilTransaction_UNSTABLE<
    [Partial<Customer> | typeof LOADING | null]
  >(
    ({ set }) =>
      (user) => {
        set($userInfo, (oldInfo) => {
          if (oldInfo === LOADING || !oldInfo) {
            return user;
          }
          if (user === LOADING || !user) {
            return user;
          }

          const balanceDelta: number =
            (user?.balance || 0) - (oldInfo?.balance || 0);
          const bonusText = numWords.bonus(balanceDelta);
          if (balanceDelta < 0) {
            toast.success(
              `С вашего счета списано ${Math.abs(balanceDelta)} ${bonusText}`
            );
          } else if (balanceDelta > 0) {
            toast.success(
              `На ваш баланс поступило ${balanceDelta} ${bonusText}`
            );
          }

          return {
            ...oldInfo,
            ...user,
          };
        });
      },
    []
  );
}

export function useUpdateUserInfo() {
  return useRecoilCallback<
    [newUser: Partial<Customer> | typeof LOADING | null, needRegister?: true],
    Promise<null | Partial<Customer> | typeof LOADING>
  >(({ set, snapshot }) => async (user, needRegister) => {
    const userInfo = await snapshot.getPromise($userInfo);

    if (userInfo === LOADING) {
      return user;
    }
    if (!user || user === LOADING) {
      return user;
    }

    const newUser: Customer = {
      ...userInfo,
      ...user,
    };
    set($userInfoUpdating, true);

    try {
      if (needRegister) {
        await registerCustomerInServer(newUser);
      } else {
        await updateUserInfoInServer(newUser);
      }
    } catch (e: any) {
      console.warn("cant send data to server", e);
    }
    set($userInfo, (oldInfo) => {
      if (oldInfo === LOADING || !oldInfo) {
        return user;
      }
      if (!user || user === LOADING) {
        return user;
      }

      return {
        ...oldInfo,
        ...user,
      };
    });

    set($userInfoUpdating, false);

    return newUser;
  });
}

export function useUserInfoIsLoading() {
  const user = useRecoilValue($userInfo);
  return user === LOADING;
}

export function useUserInfoIsUpdating() {
  return useRecoilValue($userInfoUpdating);
}

export function useUserProfileField() {
  return useRecoilValue($userProfileField);
}
