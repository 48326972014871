import React from "react";
import { Row, Spin } from "antd";

const styleVariants: { [key in LoaderSize]: React.CSSProperties } = {
  s: {},
  m: {
    marginTop: 150,
  },
  l: {
    height: "100vh",
    alignItems: "center",
  },
};

export type LoaderSize = "s" | "m" | "l";

const Loader: React.FC<{ style?: React.CSSProperties; size?: LoaderSize }> = ({
  style,
  size,
}) => (
  <Row
    justify={"center"}
    style={{
      ...style,
      ...styleVariants[size || "s"],
    }}
  >
    <Spin />
  </Row>
);

export default Loader;
