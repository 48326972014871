import { Customer, SexVariants } from "app/state/auth/userInfo";
import { app } from "app/api/firebase";
import dayjs from "dayjs";

export function prepareUserInfo(user: Customer): Required<Customer> {
  return {
    balance: user.balance || 0,
    firstName: user.firstName || "",
    birthday: user.birthday || dayjs().toISOString(),
    lastName: user.lastName || "",
    cardNumber: user.cardNumber || user.phone || "",
    phone: user.phone || "",
    city: user.city || "",
    customerId: user.customerId || "",
    email: user.email || "",
    favoritePlace: user.favoritePlace || "",
    howFindOut: user.howFindOut || "",
    sex: user.sex || SexVariants.male,
    iIkoCardsId: user.iIkoCardsId || "",
  };
}

export async function getUserInfo(): Promise<Customer | null> {
  try {
    const user: Customer = await app
      .functions()
      .httpsCallable("getUserInfo")()
      .then((d) => d.data);

    if (!user) {
      return null;
    }

    return user;
  } catch (e) {
    console.warn("Cant get user. ", e);
    return null;
  }
}
