import React from "react";
import { RouteProps, Switch, Route, Redirect } from "react-router-dom";
import RegisterInBonusProgramPage from "pages/registerCard";
import { useIsAuth, useUserProfileField } from "app/state/auth/useUserInfo";
import ShowBalancePage from "pages/showBalance";
import { urlFns, urlFnsGuest } from "./constants";

export const routesListGuest: RouteProps[] = [
  {
    path: urlFnsGuest.registerInBonusProgram(),
    component: RegisterInBonusProgramPage,
  },
];

export const routesListAuthorized: RouteProps[] = [
  {
    path: urlFns.showBalance(),
    component: ShowBalancePage,
  },
];

const RoutesListGuest: React.FC = () => (
  <Switch>
    {routesListGuest.map((props) => (
      <Route {...props} key={props.path as string} />
    ))}
    <Redirect to={urlFnsGuest.registerInBonusProgram()} />
  </Switch>
);

const RoutesListAuthorized: React.FC = () => (
  <Switch>
    {routesListAuthorized.map((props) => (
      <Route {...props} key={props.path as string} />
    ))}
    <Redirect to={urlFns.showBalance()} />
  </Switch>
);

export const RoutesList: React.FC = () => {
  const isAuth = useIsAuth();

  const profileFieldIn = useUserProfileField();

  if (isAuth && profileFieldIn) {
    return <RoutesListAuthorized />;
  }
  return <RoutesListGuest />;
};
