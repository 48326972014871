import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Row, Select } from "antd";
import { Customer } from "app/state/auth/userInfo";
import {
  useUpdateUserInfo,
  useUserInfoIsUpdating,
} from "app/state/auth/useUserInfo";
import Loader from "components/loader";
import { useRegisterInBonusProgramAsyncParamsData } from "app/state/registerInBonusProgram";

const RegisterInBonusProgramEnterUserInformationUserInfo: React.FC = () => {
  const updateUserInfo = useUpdateUserInfo();
  const userInfoUpdating = useUserInfoIsUpdating();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const asyncData = useRegisterInBonusProgramAsyncParamsData();
  const [selectedCityId, setSelectedCityId] = useState<string>(
    Object.values(asyncData.cities)[0]?.id || ""
  );
  const selectedCity = asyncData.cities[selectedCityId];
  const onSubmit = async (formData: Customer) => {
    setLoading(true);
    const { birthday } = formData;
    const updateRes = await updateUserInfo(
      {
        ...formData,
        birthday,
      },
      true
    );

    setLoading(false);
    console.log(updateRes);
  };

  return (
    <Form onFinish={onSubmit} form={form}>
      {userInfoUpdating && <Loader />}
      <Form.Item
        name={"firstName"}
        rules={[
          {
            required: true,
            message: "Введите имя!",
          },
        ]}
        label={"Имя"}
        required
      >
        <Input placeholder={"Имя"} />
      </Form.Item>
      <Form.Item
        name={"lastName"}
        rules={[
          {
            required: true,
            message: "Введите фамилию!",
          },
        ]}
        label={"Фамилия"}
        required
      >
        <Input placeholder={"Фамилия"} />
      </Form.Item>
      <Form.Item
        name={"email"}
        rules={[
          {
            type: "email",
            required: true,
            message: "Введите почту!",
          },
        ]}
        label={"e-mail"}
        required
      >
        <Input placeholder={"e-mail"} />
      </Form.Item>
      <Form.Item
        name={"birthday"}
        required
        label={"Дата рождения"}
        rules={[
          {
            required: true,
            message: "Выберите дату рождения",
          },
        ]}
      >
        <Input type={"date"} lang={"ru"} placeholder={"Дата рождения"} />
      </Form.Item>
      <Form.Item
        name={"sex"}
        label={"Пол"}
        required
        rules={[
          {
            required: true,
            message: "Выберите пол",
          },
        ]}
      >
        <Select placeholder={"-- Выбрать --"}>
          <Select.Option value={1}>Мужской</Select.Option>
          <Select.Option value={2}>Женский</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={"city"}
        label={"Город"}
        required
        rules={[
          {
            required: true,
            message: "Выберите город",
          },
        ]}
      >
        <Select
          placeholder={"-- Выбрать --"}
          onChange={(value: string) => {
            setSelectedCityId(value);
            const selectedCityRests = asyncData.cities[value].restaurants || [];
            form.setFields([
              {
                name: "favoritePlace",
                value: selectedCityRests[0]?.code,
              },
              {
                name: "city",
                value: asyncData.cities[value].name,
              },
            ]);
          }}
        >
          {Object.values(asyncData?.cities).map((city) => (
            <Select.Option value={city.id || ""} key={city.id}>
              {city.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={"favoritePlace"}
        label={"Любимая раковарня"}
        required
        rules={[
          {
            required: true,
            message: "Выберите любимую раковарню",
          },
        ]}
      >
        <Select placeholder={"-- Выбрать --"}>
          {selectedCity?.restaurants?.map((rest) => (
            <Select.Option value={rest?.code} key={rest.id}>
              {rest?.address}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={"howFindOut"}
        label={"Откуда вы о нас узнали"}
        required
        rules={[
          {
            required: true,
            message: "Выберите один из вариантов",
          },
        ]}
      >
        <Select placeholder={"-- Выбрать --"}>
          <Select.Option value={1}>Социальные сети</Select.Option>
          <Select.Option value={2}>Реклама в интернете</Select.Option>
          <Select.Option value={3}>Друзья и знакомые</Select.Option>
          <Select.Option value={4}>Проезжал мимо</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        required
        name={"iAmAccept"}
        initialValue={false}
        valuePropName={"checked"}
        rules={[
          {
            type: "boolean",
            required: true,
            transform: (value) => value || undefined,
            message: "Обязательно",
          },
        ]}
      >
        <Checkbox>Я согласен на обработку персональных данных</Checkbox>
      </Form.Item>
      <Form.Item
        required
        name={"iAmAccept2"}
        initialValue={false}
        valuePropName={"checked"}
        rules={[
          {
            type: "boolean",
            required: true,
            transform: (value) => value || undefined,
            message: "Обязательно",
          },
        ]}
      >
        <Checkbox>
          Я согласен с условиями программы лояльности ознакомлен и согласен
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Row justify={"end"}>
          <Button
            type={"primary"}
            htmlType={"submit"}
            loading={loading}
            disabled={loading}
          >
            Далее
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default RegisterInBonusProgramEnterUserInformationUserInfo;
