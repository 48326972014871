export enum UrlFnNamesGuest {
  registerInBonusProgram = "registerInBonusProgram",
}

export const urlFnsGuest: {
  [key in UrlFnNamesGuest]: (...args: never[]) => string;
} = {
  [UrlFnNamesGuest.registerInBonusProgram]: () => `/registerInBonusProgram`,
};

export enum UrlFnNames {
  showBalance = "showBalance",
}

export const urlFns: { [key in UrlFnNames]: () => string } = {
  [UrlFnNames.showBalance]: () => `/showBalance`,
};
