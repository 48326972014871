import { atom, selector } from "recoil";

export enum SexVariants {
  male = 1,
  female = 2,
  noInfo = 0,
}

export interface Customer {
  firstName?: string;
  lastName?: string;
  birthday?: string;
  balance?: number;
  sex?: SexVariants;
  city?: string;
  favoritePlace?: string;
  cardNumber?: string;
  phone?: string;
  email?: string;
  howFindOut?: string;
  customerId?: string;
  iIkoCardsId?: string;
}

export const LOADING = "LOADING";
export const UPDATING = "UPDATING";

export const $userInfo = atom<Customer | null | typeof LOADING>({
  key: "userInfo",
  default: LOADING,
});

export const $userInfoIfAuth = selector<Customer>({
  key: "userInfoIfAuth",
  get({ get }) {
    const user = get($userInfo);
    if (!user || user === LOADING || user) {
      throw "User not found";
    }
    return user;
  },
});

export const $userInfoUpdating = atom<boolean>({
  key: "userInfoUpdating",
  default: false,
});

export const $isAuth = selector<boolean>({
  key: "isAuth",
  get({ get }) {
    const user = get($userInfo);
    return !!user;
  },
});

export const $userProfileField = selector<boolean>({
  key: "profileFieldIn",
  get: ({ get }) => {
    const user = get($userInfo);

    return Boolean(user !== LOADING && user?.firstName);
  },
});
