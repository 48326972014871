import React, { useState } from "react";
import { Button, Form, Input, Row } from "antd";
import { rusPhonePattern } from "lib/regex";
import ReactInputMask from "react-input-mask";
import {
  useUpdateRegisterInBonusProgramState,
  useUpdateRegisterInBonusProgramStep,
} from "app/state/registerInBonusProgram";
import { sendSmsFromServer } from "app/api/sendData/sendSms";
import { RegisterInBonusProgramStepVariants } from "app/state/registerInBonusProgram/registerInBonusProgramStepVariants";
import { toast } from "react-hot-toast";

const EnterPhoneNumber: React.FC = () => {
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(false);
  const updateRegisterInBonusProgramState =
    useUpdateRegisterInBonusProgramState();
  const setRegisterInBonusProgramStep = useUpdateRegisterInBonusProgramStep();

  const handleFormChange = async () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };

  const onSubmit = async () => {
    const formData = await form.validateFields();
    setDisabledSave(true);
    try {
      const result = await sendSmsFromServer(formData.phone);

      if (!result.isOk) {
        toast.error("Неверный код.");
        setDisabledSave(false);
        return;
      }

      updateRegisterInBonusProgramState({
        phone: formData.phone,
      });
      setRegisterInBonusProgramStep(
        RegisterInBonusProgramStepVariants.verifyPhone
      );
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <Form onFinish={onSubmit} form={form} onFieldsChange={handleFormChange}>
      <Form.Item name={"phone"}>
        <ReactInputMask
          mask={"+7 (999) 999-99-99"}
          placeholder={"Номер телефона"}
        >
          {() => (
            <Input placeholder={"Номер телефона"} pattern={rusPhonePattern} />
          )}
        </ReactInputMask>
      </Form.Item>
      <Form.Item>
        <Row justify={"end"}>
          <Button
            htmlType={"submit"}
            type={"primary"}
            disabled={disabledSave}
            loading={disabledSave}
          >
            Далее
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default EnterPhoneNumber;
