import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Row } from "antd";
import {
  useRegisterInBonusProgramData,
  useUpdateRegisterInBonusProgramStep,
} from "app/state/registerInBonusProgram";
import { RegisterInBonusProgramStepVariants } from "app/state/registerInBonusProgram/registerInBonusProgramStepVariants";
import { verifySms } from "app/api/sendData/verifySms";
import { sendSmsFromServer } from "app/api/sendData/sendSms";
import { loginWithCustomToken } from "app/api/firebase/helpers";
import { toast } from "react-hot-toast";

const TIME_TO_RESEND_CODE = 60;

const RegisterInBonusProgramVerifyPhoneNumber: React.FC = () => {
  const [seconds, setSeconds] = useState(TIME_TO_RESEND_CODE);
  const interval = useRef<NodeJS.Timeout>();
  const { phone } = useRegisterInBonusProgramData();
  const setRegisterInBonusProgramStep = useUpdateRegisterInBonusProgramStep();
  const [loading, setLoading] = useState<boolean>(false);
  const goToPreviousStep = () => {
    setRegisterInBonusProgramStep(
      RegisterInBonusProgramStepVariants.enterPhone
    );
  };

  const [form] = Form.useForm();

  const startIntervalToResendCode = () => {
    setSeconds(TIME_TO_RESEND_CODE);
    if (interval.current) {
      clearInterval(interval.current);
    }
    interval.current = setInterval(() => {
      setSeconds((sec) => {
        if (sec < 1 && interval.current) {
          clearInterval(interval.current);
          return 0;
        }
        return sec - 1;
      });
    }, 1000);
  };

  // const goToNextStep = () => {
  //   setRegisterInBonusProgramStep(
  //     RegisterInBonusProgramStepVariants.enterUserInformation
  //   );
  // };

  const onSubmit = async (formData: { code: string }) => {
    if (!phone) {
      return;
    }
    setLoading(true);
    try {
      const token = await verifySms({
        phone,
        code: formData.code,
      });

      await loginWithCustomToken(token);
    } catch (e) {
      console.warn("Cant auth", e);
      form.resetFields(["code"]);
      toast.error("Неверный код");
    }
    setLoading(false);
  };

  const resendCode = () => {
    if (!phone) {
      return;
    }
    sendSmsFromServer(phone);
    startIntervalToResendCode();
  };

  useEffect(() => {
    startIntervalToResendCode();

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []);

  return (
    <Form onFinish={onSubmit} form={form}>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Введите код из сообщения",
          },
        ]}
        name={"code"}
        style={{
          marginBottom: 8,
        }}
      >
        <Input
          width={"100%"}
          placeholder={"Код подтверждения"}
          style={{
            width: "100%",
          }}
        />
      </Form.Item>

      <Row
        style={{
          marginBottom: 30,
        }}
      >
        {seconds ? (
          <div>Повторная отправка кода возможна через {seconds} сек.</div>
        ) : (
          <Button type={"link"} onClick={resendCode}>
            Отправить код повторно
          </Button>
        )}
      </Row>

      <Row justify={"space-between"}>
        <Form.Item>
          <Button type={"default"} onClick={goToPreviousStep}>
            Другой номер
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            htmlType={"submit"}
            type={"primary"}
            disabled={loading}
            loading={loading}
          >
            Далее
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default RegisterInBonusProgramVerifyPhoneNumber;
