export function numWord(
  value: number,
  words: [string, string, string]
): string {
  const partValue = Math.abs(value) % 100;
  const num = partValue % 10;
  if (partValue > 10 && partValue < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
}

export const numWords = {
  bonus: (num?: number) => numWord(num || 0, ["бонус", "бонуса", "бонусов"]),
};
